.Hero{
  display: flex;
  width: 100vw;
  background-color: var(--color-tiles-bg);
  height: calc(100vh - 100px);
  padding: 0px 30px;
}
.right-col{
flex:2;
position: relative;
bottom: 0px;
top: 10px;
}
.left-col{
  display: flex;
  flex-direction: column;
  min-width: 35vw;
  padding: 0 40px;
}
.left-col p:first-child{
  width: 75%;
  padding-top: 15vh;
color: var(--color-bright-green);
margin-bottom: 15px;
}
.para {
font-size: 1vw;
letter-spacing: 1px;
width: 35vw;
margin: 10px 0px 30px;
color:var(--color-text-secondary);
}

h1{
  font-size: 5.5vw;
line-height: 1;
margin-bottom: 20px;
}
h1 span {
  font-size: 6.5vw;
  display: flex;
}
.person{
  background-image: url('../../assets/personme.webp');
  height: 700px;
  width: 500px;
  background-size: contain;
  background-repeat: no-repeat;
  position: absolute;
  bottom: 10px;
  right: 10vw;
  z-index: 5;
}

.ring{
  background-image: var(--ring);
  width: 700px;
  height: 700px;
  position: absolute;
  bottom: 10px;
  right: 5vw;
}
.icon{
  position: absolute;
  display: grid;
  place-items: center;
  font-size: 2rem;
  font-weight: 500;
  width: 95px;
  height: 95px;
 border-radius: 50%;
  background-color: var(--color-icons-bg);
  color: var(--color-bright-green);
  transition: .25s all linear;
  box-shadow: 5px 5px 15px 5px rgba(0,0,0,0.25);
}
.icon:hover{
animation: alternate infinite .5s float;
}
.pushLeftIcons{
  transform: translateX(-50px) !important;
}

.pushRightIcons{
  transform: translateX(50px) !important;
}

.bRight{
  right: 85px;
  top: 450px;
  }
  .bLeft{
top: 450px;
right: 42vw;
}
  .tRight{
    right: 170px;
    top: 100px;
  }
  .tLeft{
right: 35vw;
top: 100px;
  }

@keyframes float {
  100%{transform: translateY(-20px);}
}


@media screen and (max-width: 1350px) {
  .left-col p:first-child{
    padding-top: 25px;
  }
  .Hero{
    height: auto;
  }
  .left-col p:first-child{
  color: var(--color-bright-green);
  margin-bottom: 15px;
  }
  .para{
    width: 250px;
  }
.ring{
  width: 570px;
  height: 570px;
}
.person{
  height: 600px;
  width: 420px;
}
.icon{
  width: 70px;
  height: 70px;
  font-size: 1.5rem;
}
.tLeft,.tRight{
  top: 150px;
  }
  .bLeft,.bRight{
  top: 350px;
  }
}
@media screen and (max-width: 1350px) and (min-width: 1150.5px) {
  .ring{
    width: 470px;
    height: 470px;
  }
  .person{
    height: 510px;
    width: 360px;
  }
}
@media screen and (max-width: 1150px) and (min-width: 820.5px) {

.ring{
  width: 400px;
  height: 400px;
}
.person{
  width: 300px;
  height:  400px;
}

.tLeft,.tRight{
  top: 50px;
  }
  .bLeft,.bRight{
  top: 280px;
  }
}

@media only screen and (max-width: 820px) and (min-width: 600.5px)  {
  .icon{
    width: 50px;
    height: 50px;
  }
  .tLeft,.tRight{
    top: 100px;
    }
    .bLeft,.bRight{
    top: 250px;
    }
  .ring{
    width: 350px;
    height: 350px;
  }
  .person{
    width: 260px;
    height:  350px;
  }
  .tRight{
    right: 100px;
  }
}

@media screen and (max-width: 600px) {
.burgerMenu{
display: block;
}
.Hero{
display: block;
}
.Hero .left-col{
display: grid;
place-items: center;
}
.Hero .left-col .btn{
margin-bottom: 50px;
}
.Hero .right-col{
height: 50vh;
display: grid;
place-items: center;
}
.ring{
  position: unset;
  width: 350px;
  height: 350px;
}
.person{
  width: 300px;
  height:  400px;
  margin-right: 10%;
}
.para {
font-size: 0.8rem;
}
.left-col p:first-child{
  padding-top: 0px;
}
h1{
font-size: 2.2rem;
}
h1 span {
font-size: 3.2rem;
}
.icon{
  width: 60px;
  height: 60px;
  font-size: 1.4rem;
}
.bRight{
right: 110px;
top: 10px;
}
.bLeft{
  right: 45px;
  top: 100px;
}
.tRight{
top: 200px;
right: 30px;
}
.tLeft{
top: 300px;
right: 60px;
}

}