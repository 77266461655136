.Skills{
display: flex;
width: 100vw;
overflow: hidden;
}

p:first-child{
padding-top: 100px;
color: var(--color-bright-green);
margin-bottom: 15px;
}
.Skills .para {
font-size: 1.1vw;
width: 100%;
}

.Skills .left-col{
display: grid;
grid-template-columns: repeat(2,1fr);
order: 1;
justify-items: center;
margin: 100px 0 100px;
}

.Skills .right-col{
padding: 50px;
order: 2;
}

@media screen and (min-width: 1150px) {
  .SkillCard:nth-child(even){
    transform: translateY(30px) !important;
    }
}

@media only screen and (max-width: 1150px) and (min-width: 600px)  {
  .SkillCard:nth-child(even){
    transform: translateY(30px) !important;
    }
}


@media only screen and (max-width: 820px) and (min-width: 600.5px)  {

  .Skills{
    display: block;
  }
  .Skills .para{
    font-size: 0.9rem;
  }
  .Skills .left-col{
    place-items: center;
    width: 100vw;
  }
  .Skills .right-col{
    padding: 0 90px 50px;
    }
}

@media screen and (max-width: 600px) {
  .Skills{
    display: block;
  }
  .Skills .left-col{
  width: 100vw;
display: flex;
  flex-direction: column;
align-items: center;
  }
  .Skills .right-col{
  height: 50vh;
  flex-direction: column;
  }
  p:first-child{
  padding: 0;
  }
  .Skills .para {
  font-size: 3vw;
  margin-bottom: 50px;
  width: 100%;
  }

}
