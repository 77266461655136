.socialIcon{
  margin-right: 5px;
  color:var(--color-text-secondary);
}
.socialIcon:hover{
  transition: .3s;
  cursor: pointer;
  color: var(--color-text-orange1);
}
.Footer{
  display: grid;
  grid-template-columns: repeat(3,1fr);
  background-color: var(--color-tiles-bg);
}
a{
  color: #fff;
}
@media screen and (max-width: 950px) {
  .Footer{
    grid-template-columns: repeat(1,1fr);
  } 
  .Footer .logo{
    display: flex;
    justify-content: center;
  }
}