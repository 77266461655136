.Projects{
display: flex;
flex-direction: column;
width: 100vw;
overflow: hidden;
margin-bottom: 100px;
  }
.Project{
  display: grid;
  grid-template-rows: repeat(1,1fr);
  grid-template-columns: repeat(2,1fr);
  height: auto;
  margin-bottom: 50px;
  }
.Projects .right-col{
padding: 0px 100px;
max-height: 600px;
  }

.Projects .left-col{
  padding-top: 50px;
display: flex;
align-items: center;
max-height: 600px;
  }
.fa-long-arrow-right{
  margin-right: 5px;
  font-size: 1.2rem;
}
.toolUsed{
  margin-left: 5px;
  font-size: 1.5rem;
}
.apicon{
  fill:var(--color-text-primary);
  width: 30px;
}
  @media screen and (max-width: 820px) {
    .Project{
      display: grid;
      grid-template-columns: repeat(1,1fr);
      gap: 50px;
    }
    .Project .para {
      font-size: 1.7vw;
      width: 70vw;
    }
  }

  @media screen and (min-width: 1000px) {
    .Project{
      grid-template-areas: "flipToRight flipToLeft ";
    }
    .flipToRight{
      grid-area: flipToRight;
    }
  }