.Testimonials{
display: flex;
flex-direction: column;
align-items: center;
width: 100vw;
overflow: hidden;
margin-bottom: 100px;
}
.contact h2::before{
content:'';
height: 5px;
width: 80px;
text-align: center;
margin: 0 auto;
display: block;
margin-bottom: 5px;
background-color: #327a56;
}
#square,.Testimonials .contact{
  background-color: var(--color-tiles-bg);
}
#square h1{
  color: var(--color-bright-green);
}
.contact p {
width: 40vw;
}
.Testimonials .Project .right-col h2 {
  font-size: 1.5rem;
}
.Testimonials .ProjectCard,.Testimonials .ProjectCardInner {
width: 400px;
}
.Testimonials .left-col,.Testimonials .right-col{
display: grid;
place-items: center;
}
#contact *{
margin-bottom: 40px;
}
@media only screen and (max-width: 1250px) and (min-width: 820.5px)  {
  #square{
    width: 190px !important;
    height: 190px !important;
  }
#bar{
width: 790px !important;
}
}
@media screen and (max-width: 820px) { 
  .Testimonials .ProjectCard,.Testimonials .ProjectCardInner {
    width: 250px;
    }
    .Testimonials .contact{
      height: 50vh !important;
      width: 80vw !important;
    }
  #square{
    width: 415px !important;
    height: 215px;
    border-radius: 20px !important;
  }
  #bar{
place-items: center !important;
height: 900px !important;
grid-template-columns: 1fr !important;
grid-template-rows: repeat(4,1fr) !important;
  }
}