.Projects .titleWraper{
margin: 50px auto;
text-align: center;
}
.bar{
background-color: var(--color-tiles-bg);
}
.cat:hover{
  color: var(--color-text-orange2);
  transform: scale(1.1);
  transition: .3s all;
}
.MERN,.MEAN,.Front,.Wordpress{
  color: var(--color-text-orange2);
}
.x{
  width: 90px !important;
  height: 30px !important;
}
.s-1{
  opacity: 0.5;
  transition: all .3s;
  transform: scale(0.9);
}
.right-col h2{
  margin-bottom: 10px;
}
@media screen and (max-width: 600px) {
.cat{
  padding: 7px;
  font-size: 14px;
}
.bar{
  width: 85vw !important;
}
}